.spinner-container {
    z-index: 3000;
    width: 100%;
    height: calc(100% - 64px);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    position: absolute;
    background: #F9FAFB;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #3B82F6;
    border-radius: 50%;
    display: inline-block;
    position: fixed;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.spinner::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #1E40AF;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ql-container a:hover::after,
.ql-container a:hover::before {
    content: none !important;
}

.ql-container a {
    color: #1677ff !important;
}

.ql-editor {
    padding: 0!important;
    font-size: 16px;
}