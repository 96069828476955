.job-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 32px;
  row-gap: 50px;
  justify-items: center;
  align-items: stretch;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
}

@media (max-width: 430px) {
  .job-cards-container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 32px;
    padding: 10px;
  }
}

.job-card {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: white;
  text-align: left;
}

.transition-margin {
  transition: margin-top 0.45s ease-in-out;
}

.spinner-container {
  z-index: 3000;
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  position: fixed;
  background: #f9fafb;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #3B82F6;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.spinner::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #1E40AF;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container-jobs{
  min-height: calc(100vh - 317px);
}