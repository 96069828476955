@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'antd/dist/reset.css';

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.full-width-height {
  width: 100%;
  height: 100%;
}