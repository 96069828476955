.custom-checkbox.ant-checkbox-wrapper-disabled {
    color: #4b5563;
    opacity: 0.8;
}

.custom-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f3f4f6;
    border-color: #d1d5db;
}

.custom-checkbox span {
    color: black;
}
